<template>
  <div class="modeifyPrice">
    <div class="modeify">
      <el-form
        :inline="true"
        label-width="140px"
        class="user-search fixed_form"
      >
        <!-- UserPin -->
        <el-form-item label="UserPin" size="mini" class="lableHeight">
          <template>
            <el-input
              v-model.trim="form.userPin"
              placeholder="Please Enter"
              size="mini"
              maxlength="100"
            />
          </template>
        </el-form-item>
        <!-- UserPin -->
        <el-form-item label="Order No" size="mini" class="lableHeight">
          <template>
            <el-input
              v-model.trim.number="form.orderId"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder="Please Enter"
              size="mini"
              maxlength="12"
            />
          </template>
        </el-form-item>
        <!-- 搜索按钮 -->
        <div style="float: right">
          <el-button size="mini" style="margin-right: 20px" @click="offReset">
            Reset
          </el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchBtn"
          >
            Search
          </el-button>
        </div>
        <el-form-item label="Pre-order Status" size="mini" class="lableHeight">
          <el-select
            v-model.trim="form.changePriceState"
            size="mini"
            placeholder="Please Enter"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- Book Time -->
        <el-form-item label="Book Time" size="mini">
          <template>
            <el-date-picker
              v-model.trim="bookTime"
              size="mini"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 380px"
              ormat="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="——"
              start-placeholder="start date"
              end-placeholder="End Date"
            />
          </template>
        </el-form-item>
      </el-form>
      <div class="panel-body">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column
            prop="orderId"
            fixed="left"
            label="Order No"
            min-width="130"
          />
          <el-table-column prop="userPin" label="UserPin" width="180" />
          <el-table-column prop="orderAmount" label="Order Amount" width="180" />
          <el-table-column prop="payRate" label="Advances Rate" width="180" />
          <el-table-column prop="bookTime" label="Book Time" width="180">
            <template slot-scope="scope">
              {{ scope.row.bookTime | filterDate }}
            </template>
          </el-table-column>
          <el-table-column
            label="Pre-order Order Status"
            min-width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.changePriceState == 1">
                Price Under Negotiation
              </span>
              <span v-if="scope.row.changePriceState == 2"> Finished Price Change </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="changePriceRemark"
            label="Price Change Remark"
            min-width="140"
          />
          <el-table-column
            label="Operation"
            width="180"
            class="Operation"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.changePriceState == 1"
                :underline="false"
                type="primary"
                @click="
                  $router.push({
                    path: '/modifyPriceManage/modifyOrderPrice',
                    query: {
                      orderId: scope.row.orderId,
                      remarks: scope.row.changePriceRemark,
                      orderNo: scope.row.orderId,
                      userPin: scope.row.userPin
                    }
                  })
                "
              >
                Modify
              </el-link>

              <el-link
                v-if="scope.row.changePriceState == 2"
                type="primary"
                class="View"
                @click="
                  $router.push({
                    path: '/modifyPriceManage/modifyOrderPrice',
                    query: {
                      orderId: scope.row.orderId,
                      remarks: scope.row.changePriceRemark,
                      orderNo: scope.row.orderId,
                      userPin: scope.row.userPin,
                      disable: true
                    }
                  })
                "
              >
                View
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="currentPages">
          <el-pagination
            :current-page="pages.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pages.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modifyList } from '@/api/modifys/modify';
import dateFormate from '../../utils/date'
export default {
  name: '',
  components: {},
  filters: {
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      hide: '', //隐藏筛选框
      form: {
        userPin: '',
        orderId: '',
        changePriceState: 1,
        startBookTime: '',
        endBookTime: ''
      },
      options: [
        // {
        //   value: undefined,
        //   label: 'All'
        // },
        {
          value: 1,
          label: 'Price Under Negotiation'
        },
        {
          value: 2,
          label: 'Finished Price Change'
        }
      ],
      tableData: [],
      pages: {
        page: 1,
        size: 10,
        total: null
      },
      bookTime: [],
      loading: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getModeifyList();
  },
  methods: {
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      let d = date.getDate();
      d = d < 10 ? '0' + d : d;
      let h = date.getHours();
      h = h < 10 ? '0' + h : h;
      let m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      let s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return MM + '-' + d + '-' + y + ' ' + h + ':' + m + ':' + s;
    },
    //获得数据
    getModeifyList() {
      modifyList(
        Object.assign(
          {
            pageNo: this.pages.page,
            pageSize: this.pages.size,
            // auditState: 0
          },
          this.form
        )
      )
        .then(res => {
          if (res.success && res.code === 200) {
            this.tableData = res.data.dataList;
            this.pages.total  = res.data.totalCount;
          } else {
            this.tableData = [];
            this.pages.total  = 0;
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
        .catch(() => {
        });
    },
    searchBtn() {
      if (this.bookTime != null) {
        this.form.startBookTime = this.bookTime[0];
        this.form.endBookTime = this.bookTime[1];
      }
      this.getModeifyList();
    },
    offReset() {
      this.bookTime = [];
      this.form = {
        changePriceState: 1
      };
      this.getModeifyList();
    },
    selectHiden() {
      this.hide = !this.hide;
    },
    handleCurrentChange(val) {
      this.pages.page = val;
      this.getModeifyList();
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.getModeifyList();
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.modeifyPrice {
  .modeify {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    padding: 20px 0 50px;
    box-sizing: border-box;
    .pageWrapper {
      /deep/.el-pagination {
        margin-top: 10px;
        float: right !important;
      }
      height: 45px;
    }
  }
  .fixed_form {
    padding: 0 20px;
    /deep/ .el-input__inner {
      width: 187px !important;
      height: 32px;
    }
    .el-button {
      font-size: 14px;
      ::v-deep .el-icon-search {
        font-size: 14px;
      }
    }
  }
  .el-form-item:last-of-type {
    /deep/ .el-input__inner {
      width: 340px !important;
    }
  }
  .lableHeight {
    /deep/ .el-form-item__label {
      line-height: 32x !important;
    }
  }
  .xiala {
    float: right;
    width: 118px;
    color: blue;
    margin: 8px 19% 0 0;
    i {
      margin-left: 10px;
    }
  }
  .View {
    margin-left: 10px;
  }
  .currentPages {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
  .panel-body {
    padding: 0 16px;
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-link--primary {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
<style lang="less">
.modeifyPrice {
  .font {
    span {
      margin-left: 8px;
    }
  }
}
.panel-body {
  padding: 0 16px;
}
</style>
